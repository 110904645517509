import React from "react";
import { Helmet } from "react-helmet";
import { Paper, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import A_propos from "../components/A_propos";
import Contact_background from "../images/Contact_background.png";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/navBar/NavBar";
import TopHeader from "../components/TopHeader";

function APropos() {
  const theme = useTheme();
  return (
    <Paper m={3}>
      <Helmet>
        <title>A Propos de Maître Kamdem Jean Faustin Nîmes Paris France</title>
        <meta
          name="description"
          content="Jean Faustin KAMDEM est Docteur en droit Avocat international inscrit au barreau de Nîmes et du Cameroun depuis plusieurs années.Il a fondé le cabinet JFK Avocat à la fin de l'année 1996. ​Son cabinet intervient sur l’ensemble du territoire français (allant du Barreau de Nîmes, Montpellier, Toulouse, Paris et outre-mer); mais également à l’étranger ( Cameroun, et bien d’autres)."
        />
      </Helmet>
      <Grid container direction="column">
        <Grid
          sx={{
            background: theme.palette.secondary.light,
            backgroundImage: { md: `url(${Contact_background})` },
            backgroundRepeat: { md: "no-repeat" },
            backgroundSize: { md: "cover" },
            opacity: 0.9,
          }}
        >
          <Grid item>
            <TopHeader />
          </Grid>
          <Grid item>
            <NavBar />
          </Grid>
        </Grid>
        <Grid item>
          <A_propos />
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default APropos;
