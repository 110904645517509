import React, { useContext } from "react";
import { Grid, Paper } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTheme } from "@mui/material/styles";

import AppContext from "../AppContext";
import Contact_background from "../images/Contact_background.png";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/navBar/NavBar";
import TopHeader from "../components/TopHeader";
import Section1 from "../components/homePage/Section1";
import Section2 from "../components/homePage/Section2";
import Section3 from "../components/homePage/Section3";
import Section5 from "../components/homePage/Section5";
import Section7 from "../components/homePage/Section7";

function HomePage() {
  const theme = useTheme();

  const { cardInfo } = useContext(AppContext);

  // Get all titles
  const titles = [];
  for (let i = 0, j = cardInfo.length; i < j; i++) {
    if (cardInfo[i].title !== "") {
      titles.push(cardInfo[i].title);
    }
  }

  return (
    <Paper>
      <Helmet>
        <title>
          Avocat et Docteur en droit à la faculté de Nîmes et Montpellier
        </title>
        <meta
          name="description"
          content="Le cabinet d’avocats de JFK c’est un savoir-faire de plusieurs années en droit de la famille, droit du travail, droit de la santé, droit commercial, droit des étrangers, droit des affaires"
        />
        <meta
          name="keywords"
          content={`avocat, barreau, france, docteur, droit, Maître,Jean,Faustin,Kamdem,Avocat,Nîmes,Montpellier,Châteauneuf Pope,Toulouse,Paris,France,Cameroun,Cabinet,Barreau,Droit,Justice,Articles,Divorce,Asile,Expulsion,Sans papier,Naturalisation,Sécurité,licenciement,Harcèlement,Discrimination,Liquidation,Fiscalité,Adoption,Autorité parentale,Filiation,Succession,Titre de séjour,Autorisation de travail,Propriété intellectuelle,Vol,Escroquerie,Abus de confiance,Homicide,Mariage,La Loi,Tribunal,Juge,Serment, ${titles.join()}`}
        />
      </Helmet>
      <Grid container>
        <Grid
          sx={{
            background: theme.palette.secondary.light,
            backgroundImage: { md: `url(${Contact_background})` },
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            opacity: 0.9,
          }}
          item
          container
        >
          <Grid item xs={12}>
            <TopHeader />
          </Grid>
          <Grid item xs={12}>
            <NavBar />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Section1 />
        </Grid>
        <Grid item xs={12}>
          <Section2 />
        </Grid>
        <Grid item xs={12}>
          <Section3 />
        </Grid>
        <Grid item xs={12}>
          <Section5 />
        </Grid>
        <Grid item xs={12}>
          <Section7 />
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default HomePage;
