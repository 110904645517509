import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, Grid } from "@mui/material";
import {
  PhoneOutlined,
  AccessTimeOutlined,
  EmailOutlined,
} from "@mui/icons-material";
import DirectionsIcon from "@mui/icons-material/Directions";

import AnalyticsTracker from "./AnalyticsTracker";
import AppContext from "../AppContext";
import logo from "../images/JFK_logo.png";

function TopHeader() {
  // Analytic event tracker
  const gaEventTracker = AnalyticsTracker("");

  const { address, operationHours, phoneNumber } = useContext(AppContext);
  const email = "jf.kamdem@wanadoo.fr";
  const navigate = useNavigate();

  // Function to get date of openning
  const todayHours = () => {
    const todayDate = new Date();
    const todayDay = todayDate.getDay();
    return operationHours[todayDay];
  };

  const todayOperationsHours = todayHours();

  const closed =
    Object.values(todayOperationsHours)[0].toLowerCase() === "ferme";

  // Function to redirect to home page
  const redirectToHomePage = (e) => {
    e.preventDefault();
    gaEventTracker("direct_page_accueil");
    navigate("/");
  };
  // Function to redirect to contact page
  const redirectToContactPage = (e) => {
    e.preventDefault();
    navigate("/contactez-nous");
  };

  return (
    <Grid
      container
      sx={{
        maxHeight: { md: 120 },
        mt: 1,
        mb: { xs: 1, md: 8 },
        justifyContent: "center",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
      }}
      spacing={2}
    >
      <Grid
        sx={{
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
        }}
        item
        onClick={redirectToHomePage}
      >
        <img src={logo} alt="Cabinet Kamdem Logo" style={{ width: 150 }} />
      </Grid>
      <Grid
        item
        container
        xs={9}
        sx={{ justifyContent: "center", alignItems: "center" }}
        md={9}
        spacing={1}
      >
        <Grid
          item
          container
          sx={{
            direction: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
          }}
          spacing={3}
        >
          <Grid item p={2}>
            <strong>
              <Chip
                p={2}
                sx={{
                  backgroundColor: "#FFF",
                }}
                icon={<DirectionsIcon />}
                label={address}
                variant="filled"
                href={"http://maps.google.com/?q=" + address}
                target="_blank"
                component="a"
                clickable
              />
            </strong>
          </Grid>
          <Grid item p={2}>
            <strong>
              <Chip
                p={2}
                sx={{
                  backgroundColor: "#FFF",
                }}
                icon={<PhoneOutlined />}
                label={phoneNumber}
                href={"tel:" + phoneNumber}
                component="a"
                clickable
              />
            </strong>
          </Grid>
          <Grid item p={2}>
            <strong>
              <Chip
                p={2}
                sx={{
                  backgroundColor: "#FFF",
                }}
                icon={<EmailOutlined />}
                label={email}
                href={"mailTo:" + email}
                component="a"
                clickable
              />
            </strong>
          </Grid>

          <Grid item p={2}>
            <strong>
              <Chip
                sx={{
                  p: 2,
                  backgroundColor: closed ? "#FF1616" : "#3AB37C",
                  color: "#fff",
                }}
                icon={<AccessTimeOutlined />}
                label={
                  closed
                    ? `Ferme le ${Object.keys(todayOperationsHours)[0]}`
                    : `Ouvert ${Object.keys(todayOperationsHours)[0]} De ${
                        Object.values(todayOperationsHours)[0]
                      }`
                }
                variant="outlined"
                clickable
                onClick={redirectToContactPage}
              />
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TopHeader;
