import React, { useState, useContext, useEffect } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import PictureDisplay from "../PictureDisplay";
import HomePagePic1 from "../../images/HomePage_Pic_1.png";
import HomePagePic2 from "../../images/HomePage_Pic_2.png";
import LayerImage from "../../images/LawyerImage.jpg";
import Section_1_HomePage from "../../images/Section_1_HomePage.png";
import AppContext from "../../AppContext";

function Section1() {
  const navigate = useNavigate();
  const theme = useTheme();
  // const pictures = [HomePagePic1, LayerImage, HomePagePic2];
  const pictureSize = {
    width: "130%",
    height: 100,
  };
  const { picture } = useContext(AppContext);
  const [displayPicture, setDisplayPicture] = useState([]);
  const [areaDisplayedImage, setareaDisplayedImage] = useState([]);

  useEffect(() => {
    const tempSect1Picture = picture.filter(
      (pic) => pic.pageSection === "HomePageSection1"
    );

    setDisplayPicture(tempSect1Picture);
    setareaDisplayedImage(tempSect1Picture.slice(0, 3));
  }, [picture]);

  const pictures = displayPicture[0]?.imageURLs;

  const redirectToContactPage = () => {
    navigate("/contactez-nous");
  };

  return (
    <Paper
      sx={{
        my: 1,
        backgroundColor: theme.palette.secondary.light,
        backgroundImage: { md: `url(${Section_1_HomePage})` },
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container p={5} spacing={2}>
        <Grid item container xs={12} md={6} spacing={1} textAlign={"center"}>
          <Grid item>
            <Typography variant="p" fontSize={16}>
              <p>
                <strong>Maître Jean Faustin KAMDEM</strong>  est{" "}
                <strong> Docteur en droit </strong> et
                <strong> Avocat </strong>
                inscrit au barreau de Nîmes et du Cameroun depuis 1996.
                <strong>
                  Il a fondé le cabinet JFK Avocat à la fin de l'année 1996.
                </strong>{" "}
                ​Son cabinet intervient sur l’ensemble du territoire français; 
                mais également à l’étranger ( au Cameroun notamment).
              </p>
              <br></br>
              <p>
                Il est diplômé d’un{" "}
                <strong> doctorat en droit des affaires </strong> à la faculté
                de droit à <strong> Montpellier </strong> (France) et a été qualifié aux
                fonctions de maître de conférences des facultés de droit.
                Il a enseigné pendant une vingtaine d'années aux universités 
                de Montpellier I et III.
              </p>
              <br></br>
              <p>
                JFK Avocat cumule plus de 20 ans d’expérience au
                service de particuliers et d’entreprises. Son cabinet a suivi
                avec succès des affaires les plus délicates avec la plus grande efficacité.
                Aujourd’hui, il intervient principalement en Droit pénal, Droit de la réparation du préjudice corporel,
                Droit de la famille et Droit des affaires.
              </p>
              <br></br>
              <p>
                Jean Faustin Kamdem travaille aussi bien en Conseil qu'en
                Contentieux entourée d'une équipe et de partenaires compétents.
              </p>
            </Typography>
          </Grid>
          <Grid
            sx={{ display: { xs: "none", md: "block" } }}
            item
            container
            justifyContent="center"
          >
            <Button variant="contained" onClick={redirectToContactPage}>
              Réserver un rendez-vous
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} p={1}>
          {pictures && (
            <PictureDisplay pictures={pictures} size={pictureSize} />
          )}
        </Grid>
        <Grid
          sx={{ display: { xs: "block", md: "none" } }}
          item
          container
          justifyContent="center"
          alignItems="center"
        >
          <Button variant="contained" onClick={redirectToContactPage}>
            Réserver un rendez-vous
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Section1;
