import { Button, Grid, Paper, Typography, Link } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../components/Footer/Footer";
import TopHeader from "../components/TopHeader";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <TopHeader />
        </Grid>
        {/* <Grid item xs={12}>
          <NavBar />
        </Grid> */}
        <Grid
          sx={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          item
          container
          direction="column"
          m={2}
        >
          <Grid item sx={12}>
            <Typography variant="h1" fontWeight="bold">
              404!
            </Typography>
          </Grid>
          <Grid item sx={12}>
            <Typography variant="p">
              Nous cherchons toujours la page que vous avez demandé.
            </Typography>
          </Grid>
          <Grid sx={{ mt: 4 }} item>
            <Button onClick={() => navigate("/")}>
              Visiter La Page D'Accueil
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PageNotFound;
