import React, { useEffect, useState, useContext } from "react";
import { Grid, IconButton } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import AppContext from "../../AppContext";
import CardComponent from "../CardComponent/CardComponent";
import Title from "../Title";

export default function Section2() {
  const { cardInfo } = useContext(AppContext);
  const [cardInfoSection2, setcardInfoSection2] = useState([]);
  const [areaDisplayed, setareaDisplayed] = useState([]);

  useEffect(() => {
    // Filtrer toutes les cartes ayant le meme type
    const tempSect2Card = cardInfo.filter(
      (card) => card.cardType === "HomePageSection2"
    );

    setcardInfoSection2(tempSect2Card);
    setareaDisplayed(tempSect2Card.slice(0, 4));
  }, [cardInfo]);

  // Function handle previous card
  const handlePreviousCardClicked = (e) => {
    e.preventDefault();
    const indexFirst = cardInfoSection2.findIndex(
      (obj) => obj.title === areaDisplayed[0].title
    );
    if (indexFirst) {
      setareaDisplayed([]);
      setareaDisplayed((prev) =>
        prev.concat(cardInfoSection2.slice(indexFirst - 1, indexFirst + 3))
      );
    }
  };

  // Function handle previous card
  const handleNextCardClicked = (e) => {
    e.preventDefault();
    const indexLast = cardInfoSection2.findIndex(
      (obj) => obj.title === areaDisplayed[areaDisplayed.length - 1].title
    );
    if (indexLast !== cardInfoSection2.length - 1) {
      setareaDisplayed([]);
      setareaDisplayed((prev) =>
        prev.concat(cardInfoSection2.slice(indexLast - 2, indexLast + 2))
      );
    }
  };

  return (
    <Grid
      sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
      container
      spacing={2}
      justifyContent="center"
    >
      <Grid item container justifyContent="left">
        <Title text1={"Nos"} text2={"Filières D'expertise"} />
      </Grid>
      <Grid item container spacing={2} justifyContent="center">
        {areaDisplayed?.map((pratice) => (
          <Grid item key={pratice.title}>
            <CardComponent
              title={pratice.title}
              text={pratice.text}
              width={280}
              height={230}
              color="dark"
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container justifyContent="right">
        <IconButton onClick={handlePreviousCardClicked}>
          <ArrowCircleLeftOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleNextCardClicked}>
          <ArrowCircleRightIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
