import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import AppContext from "./AppContext";
import Apropos from "./pages/APropos";
import ContactPage from "./pages/ContactPage";
import Expertise from "./pages/Expertise";
import getBusinessInfo from "./apis/getBusinessInfo";
import getCardsInfo from "./apis/getCardsInfo";
import getExpertise from "./apis/getExpertise";
import getHonoraires from "./apis/getHonoraires";
import getPicture from "./apis/getPicture";
import Honoraires from "./pages/Honoraires";
import HomePage from "./pages/HomePage";
import Loading from "./components/Loading";
import LazyLoadingPage from "./pages/LazyLoadingPage";
import PageNotFound from "./pages/PageNotFound";
import { theme } from "./styles";

//import ReactGA from "react-ga";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-0XKQLRJFSP";
// last Tracking id
// const TRACKING_ID = "UA-239552284-1"; // "G-DFRMTEQN8J" // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// require("dotenv").config();

function App() {
  // Create states to use
  const [themeSwitch, setThemeSwitch] = useState(
    localStorage.getItem("themeSwitch")
      ? localStorage.getItem("themeSwitch")
      : "light"
  );
  const [businessInfo, setbusinessInfo] = useState({});
  const [isLoading, setisLoading] = useState(true);

  // Const for business info
  const [address, setaddress] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [operationHours, setoperationHours] = useState([]);

  const [cardInfo, setcardInfo] = useState([]);
  const [expertiseInfo, setexpertiseInfo] = useState([]);
  const [honorairesInfo, sethonorairesInfo] = useState([]);
  const [picture, setPicture] = useState([]);

  useEffect(() => {
    const getcard = async () => {
      setcardInfo(await getCardsInfo());
    };

    getcard();
  }, []);

  useEffect(() => {
    const getImage = async () => {
      const tmp = await getPicture();
      setPicture(tmp);
    };

    getImage();
  }, []);

  useEffect(() => {
    const getDomaineCompetence = async () => {
      setexpertiseInfo(await getExpertise());
    };

    getDomaineCompetence();
  }, []);

  useEffect(() => {
    const gethonoraire = async () => {
      sethonorairesInfo(await getHonoraires());
    };

    gethonoraire();
  }, []);

  useEffect(() => {
    const getInfo = async () => {
      setbusinessInfo(await getBusinessInfo());
    };

    getInfo();
  }, []);

  useEffect(() => {
    if (Object.entries(businessInfo).length === 0) {
      setisLoading(true);
    } else {
      setaddress(
        businessInfo?.companyAddress[0].address_1.address +
          ", " +
          businessInfo?.companyAddress[0].address_1.city +
          ", " +
          businessInfo?.companyAddress[0].address_1.country +
          ", " +
          businessInfo?.companyAddress[0].address_1.postalCode
      );
      setphoneNumber(businessInfo?.phoneNumbers[0]);
      setoperationHours((prev) => prev.concat(businessInfo?.operationHours));
      setisLoading(false);
    }
  }, [businessInfo]);

  // Actualize theme saved
  useEffect(() => {
    localStorage.setItem("themeSwitch", themeSwitch);
  }, [themeSwitch]);

  // Get page visited
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <AppContext.Provider
      value={{
        address,
        businessInfo,
        cardInfo,
        expertiseInfo,
        honorairesInfo,
        picture,
        operationHours,
        phoneNumber,
        themeSwitch,
        setThemeSwitch,
        setisLoading,
      }}
    >
      <ThemeProvider theme={theme(themeSwitch)}>
        <BrowserRouter>
          {!isLoading ? (
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/contactez-nous" element={<ContactPage />} />
              <Route path="/a-propos" element={<Apropos />} />
              <Route path="/domaine-de-competence" element={<Expertise />} />
              <Route path="/honoraire" element={<Honoraires />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          ) : (
            <LazyLoadingPage />
          )}
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
