import axios from "axios";

const getPicture = async () => {
  try {
    let picture = "";
    await axios.get("/api/picture").then((res) => {
      if (res.status === 200) {
        picture = res?.data?.Pics;
      } else {
        picture = undefined;
      }
    });
    return picture;
  } catch (error) {
    console.log("Error getting pictures ", error);
  }
};

export default getPicture;
