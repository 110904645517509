import React, {useContext, useState, useEffect} from 'react';
import {
  Alert,
  Button,
  Chip,
  Grid,
  Link,
  Modal,
  TextField,
  Typography,
  TextareaAutosize,
} from '@mui/material';
import {PhoneOutlined, LinkedIn} from '@mui/icons-material';

import AppContext from '../AppContext';
import GoogleMap from './GoogleMap.js';
import {linkedIn, location} from './Footer/data';
import sendContactEmail from '../apis/sendContactEmail';
import Title from './Title';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 25,
  p: 4,
  borderRadius: 5,
  backgroundColor: '#3AB37C',
};

function Contact_Us () {
  // TODO: Get this data from DB
  const date = 'Lundi-Vendredi';
  const heure = '9h-18h';
  const oparationHour = 'Fermé le week-end ';
  const [name, setName] = useState ('');
  const [email, setEmail] = useState ('');
  const [phone, setPhone] = useState ('');
  const [note, setNote] = useState ('');
  const [emailSent, setEmailSent] = useState (false);
  const [inputError, setInputError] = useState (false);
  const {phoneNumber} = useContext (AppContext);

  const handleName = event => {
    let tmp_name = event.target.value;
    setName (tmp_name);
  };
  const handlePhone = event => {
    let tmp_num = event.target.value;
    setPhone (tmp_num);
  };
  const handleEmail = event => {
    let tmp_mail = event.target.value;
    setEmail (tmp_mail);
  };
  const handleMessage = event => {
    let tmp_note = event.target.value;
    setNote (tmp_note);
  };

  const handleCloseModal = () => {
    setEmailSent (false);
  };

  const submitForm = async e => {
    e.preventDefault ();
    if (name && phone && email && note) {
      setInputError (false);
      await sendContactEmail (name, email, phone, note).then (response => {
        if (response) {
          setEmailSent (response);
          setName ('');
          setEmail ('');
          setNote ('');
          setPhone ('');
        }
      });
    } else {
      setInputError (true);
    }
  };

  useEffect (
    () => {
      if (emailSent) {
        setTimeout (() => {
          setEmailSent (false);
        }, 5000);
      }
    },
    [emailSent]
  );

  return (
    <Grid
      sx={{direction: {xs: 'column', md: 'row'}}}
      container
      p={4}
      spacing={2}
      className="contact"
    >
      <Grid item container xs={12} md={4} direction="row">
        <Grid item xs={12}>
          <Title
            text1="Besoin D'aide?"
            text2="CONTACTEZ-NOUS"
            style={{pt: 0}}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Typography fontSize="18" fontWeight="100">
            Pour Bénéficier d'une première consultation gratuite, Veuillez
            remplir le formulaire.
          </Typography>
        </Grid>*/}
        {inputError &&
          <Alert severity="error">
            Veuillez entrer toutes les informations requises.
          </Alert>}
        <Grid item container direction="row" xs={12}>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Nom"
              value={name}
              sx={{marginTop: 1, marginBottom: 1, width: '100%'}}
              onChange={e => handleName (e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Adresse Email"
              value={email}
              sx={{marginTop: 1, marginBottom: 1, width: '100%'}}
              onChange={e => handleEmail (e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Numéro de téléphone"
              value={phone}
              sx={{marginTop: 1, marginBottom: 2, width: '100%'}}
              onChange={e => handlePhone (e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              aria-label="empty textarea"
              style={{width: '100%'}}
              p={2}
              variant="outlined"
              name="note"
              minRows="4"
              multiline="true"
              placeholder="Entrez votre message ici"
              onChange={e => handleMessage (e)}
              value={note}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{marginRight: 1}}
            onClick={submitForm}
          >
            Envoyer
          </Button>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={3}>
        <Grid
          item
          sx={{
            mt: {xs: 0, md: 10},
            textAlign: {xs: 'left', md: 'center'},
          }}
          xs={12}
        >
          <Typography fontSize="24" fontWeight="bold">
            HEURES D'OUVERTURE
          </Typography>
          <Typography fontSize="18">{date} </Typography>
          <Typography fontSize="18">{heure}</Typography>
          <Typography fontSize="18">{oparationHour}</Typography>
        </Grid>
        <Grid
          item
          sx={{
            mt: {xs: 2},
            textAlign: {xs: 'left', md: 'center'},
          }}
          xs={12}
        >
          <Grid item>
            <Typography fontSize="24" fontWeight="bold">
              ADRESSES
            </Typography>
          </Grid>
          <Grid
            sx={{
              textAlign: {xs: 'left', md: 'center'},
            }}
            item
            container
          >
            {location.map ((location, index) => (
              <Grid item key={index} xs={12}>
                <Link
                  fontSize="18"
                  href={location.url}
                  target="_blank"
                  className="footer-links-item"
                  underline="none"
                  color="black"
                >
                  {location.name}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          container
          sx={{
            mt: {xs: 2},
            alignItems: {xs: 'left', md: 'center'},
          }}
          xs={12}
          spacing={1}
          direction="column"
        >
          <Grid item>
            <Chip
              icon={<PhoneOutlined />}
              label={phoneNumber}
              href={'tel:' + phoneNumber}
              component="a"
              clickable
            />
          </Grid>
          <Grid item>
            <Chip
              icon={<LinkedIn />}
              label={linkedIn.label}
              href={linkedIn.link}
              component="a"
              target="_blank"
              clickable
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container md={5}>
        <GoogleMap />
      </Grid>
      {emailSent &&
        <Modal
          open={emailSent}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            sx={modalStyle}
            textAlign="center"
            justifyContent={'center'}
            direction="column"
          >
            <Alert
              severity="success"
              sx={{
                backgroundColor: 'transparent',
                color: '#fff',
                fontSize: 14,
                fontWeight: 'bold',
                letterSpacing: 0.5,
              }}
            >
              Votre email a été envoyé au cabinet de Maitre Kamdem!
            </Alert>
          </Grid>
        </Modal>}
    </Grid>
  );
}
export default Contact_Us;
