import axios from "axios";

const getBusinessInfo = async () => {
  try {
    let infoFound = "";
    await axios.get("/api/businessinfo/").then((res) => {
      if (res.status === 200) {
        infoFound = res?.data?.businessInfo[0];
      } else {
        infoFound = undefined;
      }
    });

    return infoFound;
  } catch (error) {
    console.log("Error getting business info", error);
  }
};

// apiConfig.request(config, (error, results) => {
//   if (error) {
//     console.error("Error:", error);
//     return;
//   }

//   console.log(results);
// });

export default getBusinessInfo;
