import { createTheme } from "@mui/material";

const theme = (theme) => {
  return theme === "light"
    ? createTheme({
        palette: {
          primary: {
            main: "#16428F",
          },
          secondary: {
            light: "#EFEFEF",
            main: "#000000",
          },
          success: {
            main: "#fff",
          },
        },
        typography: {
          fontFamily: "'Lato' sans-serif",
          h3: {
            fontWeight: 700,
            fontSize: "2.2rem",
          },
          h4: {
            fontWeight: 700,
            fontSize: "1.75rem",
          },
          h5: {
            fontWeight: 500,
          },
          h6: {
            fontWeight: 500,
          },
        },
      })
    : createTheme({
        palette: {
          mode: "dark",
          primary: {
            main: "#EDB96F",
          },
          secondary: {
            main: "rgba(255, 255, 255, 0.6)",
            light: "#000000",
          },
          success: {
            main: "#4caf50",
          },
          background: {
            default: "#111111",
            paper: "#111111",
          },
        },
        typography: {
          fontSize: 16,
          h1: { color: "#fff" },
          h3: { color: "#fff", fontWeight: 700, fontSize: "2.2rem" },
          h4: {
            fontWeight: 700,
            fontSize: "1.75rem",
          },
          h5: {
            fontWeight: 500,
          },
          h6: {
            fontWeight: 500,
          },
        },
      });
};

theme.props = {
  MuiButton: {
    disableELevation: false,
  },
};

export { theme };
