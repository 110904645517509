import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  AppBar,
  Chip,
  Divider,
  Grid,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';

import AppContext from '../../AppContext';
import {PhoneOutlined, Email, LinkedIn} from '@mui/icons-material';
import {connection, email, Pages, location, lsuLink, linkedIn} from './data';

import logo from '../../images/JFK_logo.png';

const LinkStyle = {
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex',
  marginTop: 10,
  cursor: 'pointer',
  textAlign: 'justify',
  fontSize: 16,
};

export default function Footer () {
  const {phoneNumber} = useContext (AppContext);
  const navigate = useNavigate ();
  const handlePageSelected = redirectTo => {
    navigate (redirectTo);
  };

  return (
    <AppBar
      position="relative"
      color="transparent"
      sx={{backgroundColor: '#C2E7FF'}}
    >
      <Toolbar>
        <Grid container>
          <Grid
            item
            container
            sx={{marginTop: 1, direction: {xs: 'column', md: 'row'}}}
          >
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
                mixBlendMode: 'luminosity',
              }}
            >
              <img src={logo} alt="Cabinet Kamdem Logo" style={{width: 100}} />
              <Typography
                md={{fontSize: 14}}
                align="left"
                width={'83%'}
                color="inherit"
              >
                Fondé à la fin de l'année 1996, le cabinet JFK Avocat intervient sur l’ensemble du territoire français; 
                mais également à l’étranger ( au Cameroun notamment).
              </Typography>
            </Grid>
            <Grid
              item
              container
              sx={{
                alignItem: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                direction: {xs: 'column', md: 'row'},
              }}
              xs={12}
              md={9}
            >
              <Grid
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  flexDirection: 'column',
                }}
                xs={12}
                md={3}
                item
                container
              >
                <Grid item>
                  <Typography
                    align="justify"
                    sx={{
                      marginBottom: 1,
                      fontSize: 20,
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}
                  >
                    Liens
                  </Typography>
                </Grid>
                <Grid item container direction="column">
                  {Pages.map ((links, index) => (
                    <Grid item>
                      <Link
                        key={index}
                        style={LinkStyle}
                        underline="hover"
                        onClick={e => {
                          handlePageSelected (links.url);
                        }}
                      >
                        <span style={{color: 'black'}}>{links.name}</span>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                container
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  flexDirection: 'column',
                }}
                xs={12}
                md={3}
              >
                <Grid item>
                  <Typography
                    align="justify"
                    sx={{
                      marginBottom: 1,
                      fontSize: 20,
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}
                  >
                    Sources
                  </Typography>
                </Grid>
                <Grid item container direction="column">
                  {connection.map ((links, index) => (
                    <Grid item>
                      <Link
                        key={index}
                        href={links.url}
                        target="_blank"
                        style={LinkStyle}
                        underline="hover"
                      >
                        <span style={{color: 'black'}}>{links.name}</span>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                container
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  flexDirection: 'column',
                }}
                xs={12}
                md={3}
              >
                <Grid item>
                  <Typography
                    align="justify"
                    sx={{
                      marginBottom: 1,
                      fontSize: 20,
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}
                  >
                    Localisation
                  </Typography>
                </Grid>
                <Grid item container direction="column">
                  {location.map ((locat, index) => (
                    <Grid item>
                      <Link
                        key={index}
                        href={locat.url}
                        target="_blank"
                        style={LinkStyle}
                        underline="hover"
                      >
                        <span style={{color: 'black'}}>{locat.name}</span>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            sx={{
              alignItem: 'center',
              justifyContent: 'center',
              width: '90%',
              direction: {xs: 'column', md: 'row'},
            }}
            spacing={4}
          >
            <Grid item>
              <Chip
                icon={<PhoneOutlined />}
                label={phoneNumber}
                href={'tel:' + phoneNumber}
                component="a"
                clickable
                sx={{marginTop: 2}}
              />
            </Grid>
            <Grid item>
              <Chip
                icon={<Email />}
                label={email}
                href={'mailTo:' + email}
                component="a"
                target="_blank"
                clickable
                sx={{marginTop: 2}}
              />
            </Grid>
            <Grid item>
              <Chip
                icon={<LinkedIn />}
                label={linkedIn.label}
                href={linkedIn.link}
                component="a"
                target="_blank"
                clickable
                sx={{marginTop: 2}}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container justifyContent="center" width={'90%'}>
            <Typography
              variant="body1"
              align="center"
              color="inherit"
              sx={{margin: 2}}
            >
              &copy;{new Date ().getFullYear ()} Tout droit reservé à &nbsp;
              <strong>
                <Link href={'https://www.avocat-kamdem.com'} underline="none">
                  Maître Jean-Faustin Kamdem
                </Link>
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
