import React, { useContext } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTheme } from "@mui/material/styles";
import ReactLoading from "react-loading";

import AppContext from "../AppContext";
import Contact_background from "../images/Contact_background.png";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/navBar/NavBar";
import Section1 from "../components/homePage/Section1";

function HomePage() {
  const theme = useTheme();

  const { cardInfo } = useContext(AppContext);

  // Get all titles
  const titles = [];
  for (let i = 0, j = cardInfo.length; i < j; i++) {
    if (cardInfo[i].title !== "") {
      titles.push(cardInfo[i].title);
    }
  }

  return (
    <Paper>
      <Helmet>
        <title>
          Avocat et Docteur en droit à la faculté de Nîmes et Montpellier
        </title>
        <meta
          name="description"
          content="Le cabinet d’avocats de JFK c’est un savoir-faire de plusieurs années en droit de la famille, droit du travail, droit de la santé, droit commercial, droit des étrangers, droit des affaires"
        />
        <meta
          name="keywords"
          content={`avocat, barreau, france, docteur, droit, Maître,Jean,Faustin,Kamdem,Avocat,Nîmes,Montpellier,Châteauneuf Pope,Toulouse,Paris,France,Cameroun,Cabinet,Barreau,Droit,Justice,Articles,Divorce,Asile,Expulsion,Sans papier,Naturalisation,Sécurité,licenciement,Harcèlement,Discrimination,Liquidation,Fiscalité,Adoption,Autorité parentale,Filiation,Succession,Titre de séjour,Autorisation de travail,Propriété intellectuelle,Vol,Escroquerie,Abus de confiance,Homicide,Mariage,La Loi,Tribunal,Juge,Serment, ${titles.join()}`}
        />
      </Helmet>
      <Grid container>
        <Grid
          sx={{ p: 5, alignItems: "center", justifyContent: "center" }}
          item
        >
          <ReactLoading
            type={"balls"}
            color={theme.palette.primary.main}
            height={100}
            width={250}
          />
        </Grid>
        <Grid item xs={12} sx={{ p: 5 }}>
          <Typography variant="p" fontSize={16}>
            <p>
              <strong>Jean Faustin KAMDEM</strong>  est
              <strong> Docteur en droit </strong> et
              <strong> Avocat international </strong>
              inscrit au barreau en France et au Cameroun depuis plusieurs
              années.
              <strong>
                Il a fondé le cabinet JFK Avocat à la fin de l'année 1996.
              </strong>
              ​Son cabinet intervient sur l’ensemble du territoire français
              (allant du Barreau de Nîmes, Montpellier, Toulouse, Paris et
              outre-mer); mais également à l’étranger ( Cameroun, et bien
              d’autres).
            </p>
            <br></br>
            <p>
              Il est diplômé en 1994 d’un
              <strong> doctorat en droit des affaires </strong> à la faculté de
              droit à <strong> Montpellier </strong> (France); où il enseigne
              également depuis de nombreuses années en qualité de maître de
              conférences des Facultés.
            </p>
            <br></br>
            <p>
              JFK Avocat cumule plus de 20 ans d’expériences réussites; au
              service de particuliers et d’entreprises. Son cabinet a suivi des
              affaires les plus délicates avec la plus grande efficacité.
              Aujourd’hui, il intervient principalement en droit pénal et en
              droit des affaires.
            </p>
            <br></br>
            <p>
              Jean Faustin Kamdem travaille aussi bien en Conseil qu'en
              Contentieux entourée d'une équipe et de partenaires compétents.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default HomePage;
