import axios from "axios";

const sendContactEmail = async (name, email, phone, message) => {
  try {
    let emailSent = "";
    const Body = {
      name,
      phone,
      email,
      message,
    };

    await axios.post("/api/sendContactEmail/", Body).then((res) => {
      console.log({ res });
      if (res.status === 200) {
        console.log("here 1");
        emailSent = res?.data?.success;
      } else {
        console.log("here 2");
        emailSent = undefined;
      }
    });

    return emailSent;
  } catch (error) {
    console.log("Error getting saveContact info", error);
  }
};
export default sendContactEmail;
