import axios from "axios";

const getCardsInfo = async () => {
  try {
    let cardInfoFound = "";
    await axios.get("/api/card/").then((res) => {
      if (res.status === 200) {
        cardInfoFound = res?.data?.cards;
      } else {
        cardInfoFound = undefined;
      }
    });

    return cardInfoFound;
  } catch (error) {
    console.log("Error getting cards info", error);
  }
};

export default getCardsInfo;
