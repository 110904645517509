import React from 'react';
import {Grid, Paper, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';

import Contact_background from '../../images/Contact_background.png';
import ContactUsButton from '../Buttons/ContactUsButton';

function Section3 () {
  const theme = useTheme ();

  return (
    <Paper
      sx={{
        background: theme.palette.secondary.light,
        backgroundImage: {md: `url(${Contact_background})`},
        backgroundRepeat: 'no-repeat',
        opacity: 0.9,
      }}
      elevation={5}
    >
      <Grid
        container
        sx={{}}
        p={2}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
      >
        <Grid item container direction="column" xs={12} md={8}>
          <Grid item>
            <Typography
              variant="h4"
              color="#000"
              fontWeight="bold"
              letterSpacing={1}
            >
              Premier rendez-vous téléphonique gratuit
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              color="#000"
              fontSize={12}
              fontWeight={500}
              letterSpacing={1}
            >
              Parce que la justice doit être accessible à tous, Maître Jean-Faustin
              KAMDEM offre personnellement un premier rendez-vous téléphonique
              gratuit.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <ContactUsButton text={'Réserver un rendez-vous'} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Section3;
