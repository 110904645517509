import React, { useContext, useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@mui/material";

import ContactUsButton from "../components/Buttons/ContactUsButton";
import HomePagePic2 from "../images/HomePage_Pic_2.png";
import image from "../images/Section_4_image.png";
import Title from "./Title";
import PictureDisplay from "./PictureDisplay";
import AppContext from "../AppContext";

function A_propos() {
  const pictureSize = {
    width: "92%",
    height: 300,
  };

  const { picture } = useContext(AppContext);
  const [displayPicture, setDisplayPicture] = useState([]);
  const [imgToDisplay, setImgToDisplay] = useState("");

  useEffect(() => {
    const tempSect1Picture = picture.filter(
      (pic) => pic.pageSection === "AProposSection1"
    );

    setDisplayPicture(tempSect1Picture[0]);
    setImgToDisplay(tempSect1Picture[0]?.imageURLs[0]);
  }, [picture]);

  return (
    <Paper
      sx={{
        // backgroundColor: theme.palette.secondary.light,
        backgroundImage: { md: `url(${image})` },
        backgroundRepeat: "no-repeat",
        backgroundPosition: "calc(100% - 10px) center",
        margin: 6,
      }}
      elevation={0}
      m={0}
    >
      <Grid container direction="row" spacing={2} textAlign="center">
        <Grid item xs={12} md={4}>
          {imgToDisplay && (
            <PictureDisplay
              picture={imgToDisplay}
              size={pictureSize}
              removeCard
            />
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          spacing={1}
          pr={2}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Grid item>
            <Title text2={"À Propos"} />
          </Grid>
          <Grid item>
            <Typography variant="p" fontSize={16}>
             <p>
                <strong>Maître Jean Faustin KAMDEM</strong>  est{" "}
                <strong> Docteur en droit </strong> et
                <strong> Avocat </strong>
                inscrit au barreau de Nîmes et du Cameroun depuis 1996.
                <strong>
                  Il a fondé le cabinet JFK Avocat à la fin de l'année 1996.
                </strong>{" "}
                ​Son cabinet intervient sur l’ensemble du territoire français; 
                mais également à l’étranger ( au Cameroun notamment).
              </p>
              <br></br>
              <p>
                Il est diplômé d’un{" "}
                <strong> doctorat en droit des affaires </strong> à la faculté
                de droit à <strong> Montpellier </strong> (France) et a été qualifié aux
                fonctions de maître de conférences des facultés de droit.
                Il a enseigné pendant une vingtaine d'années aux universités 
                de Montpellier I et III.
              </p>
              <br></br>
              <p>
                JFK Avocat cumule plus de 20 ans d’expérience au
                service de particuliers et d’entreprises. Son cabinet a suivi
                avec succès des affaires les plus délicates avec la plus grande efficacité.
                Aujourd’hui, il intervient principalement en Droit pénal, Droit de la réparation du préjudice corporel,
                Droit de la famille et Droit des affaires.
              </p>
              <br></br>
              <p>
                Jean Faustin Kamdem travaille aussi bien en Conseil qu'en
                Contentieux entourée d'une équipe et de partenaires compétents.
              </p>
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center" mb={2}>
            <ContactUsButton
              text={"Profitez d'une consultation gratuite"}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default A_propos;
