import React, {useContext} from 'react';
import {Grid, Paper} from '@mui/material';
import {Helmet} from 'react-helmet';
import {useTheme} from '@mui/material/styles';

import AppContext from '../AppContext';
import Contact_background from '../images/Contact_background.png';
import Footer from '../components/Footer/Footer';
import NavBar from '../components/navBar/NavBar';
import TopHeader from '../components/TopHeader';
import Title from '../components/Title';
import CardComponent from '../components/CardComponent/CardComponent';

function Expertise () {
  const theme = useTheme ();
  const {expertiseInfo} = useContext (AppContext);
  const titles = [];
  for (let i = 0, j = expertiseInfo.length; i < j; i++) {
    if (expertiseInfo[i].title !== '') {
      titles.push (expertiseInfo[i].title);
    }
  }

  return (
    <Paper m={3}>
      <Helmet>
        <title>
          Avocat et Docteur Expert en droit à la faculté de Nîmes et Montpellier
        </title>
        <meta
          name="description"
          content="Le cabinet d’avocats de JFK c’est un savoir-faire de plusieurs années en droit de la famille, droit du travail, droit de la santé, droit commercial, droit des étrangers, droit des affaires"
        />
        <meta
          name="keywords"
          content={`avocat, barreau, france, docteur, droit, ${titles.join ()}`}
        />
      </Helmet>
      <Grid container direction="column">
        <Grid
          sx={{
            background: theme.palette.secondary.light,
            backgroundImage: {md: `url(${Contact_background})`},
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.9,
          }}
        >
          <Grid item>
            <TopHeader />
          </Grid>
          <Grid item>
            <NavBar />
          </Grid>
        </Grid>
        <Grid item px={5} pb={3}>
          <Title text1="Domaine De " text2="Compétences" />
        </Grid>
        <Grid
          sx={{marginRight: 4, flexDirection: {xs: 'row', md: 'row'}}}
          container
          spacing={3}
          justifyContent="space-between"
          p={4}
        >
          {expertiseInfo.map ((expertise, index) => {
            return (
              <Grid item key={index}>
                <CardComponent
                  title={expertise.name}
                  text={expertise.description}
                  href={expertise.others[0]}
                  textPreviewLength={150}
                  width={290}
                  height={320}
                  color="dark"
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item>
        <Footer />
      </Grid>
    </Paper>
  );
}

export default Expertise;
