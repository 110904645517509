import React from "react";

import { Grid } from "@mui/material";

function Section7() {
  // TODO: This should come from DB
  const embedId = "eGuDL2FQU_g";

  return (
    <Grid sx={{ p: { xs: 0, md: 2 } }} container justifyContent="center">
      <Grid
        item
        sx={{
          backgroundColor: "transparent",
          display: { xs: "none", md: "block" },
        }}
      >
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Grid>
      <Grid
        item
        sx={{
          backgroundColor: "transparent",
          display: { xs: "block", md: "none" },
        }}
      >
        <iframe
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Maitre Jean Faustain Kamdem Reagit à l'affaire Badre Fakir"
        />
      </Grid>
    </Grid>
  );
}

export default Section7;
