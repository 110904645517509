import axios from "axios";

const getHonoraires = async () => {
  try {
    let honoraire = "";
    await axios.get("/api/honoraire").then((res) => {
      if (res.status === 200) {
        honoraire = res?.data?.Hons;
      } else {
        honoraire = undefined;
      }
    });

    return honoraire;
  } catch (error) {
    console.log("Error getting honoraire info", error);
  }
};
export default getHonoraires;