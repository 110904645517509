import React, { useState, useContext, useEffect } from "react";
import { Grid, Link, IconButton } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import AppContext from "../../AppContext";
import Article_img1 from "../../images/Article_img1.png";
import CardComponent from "../CardComponent/CardComponent";
import Title from "../Title";

function Section5() {
  const { cardInfo } = useContext(AppContext);
  const [cardInfoSection5, setcardInfoSection5] = useState([]);
  const [areaDisplayed, setareaDisplayed] = useState(
    cardInfoSection5.slice(0, 3)
  );
  //
  useEffect(() => {
    // Filtrer toutes les cartes ayant le meme type
    const tempSect5Card = cardInfo.filter(
      (card) => card.cardType === "HomePageSection5"
    );

    setcardInfoSection5(tempSect5Card);
    setareaDisplayed(tempSect5Card.slice(0, 3));
  }, [cardInfo]);
  //
  // Function handle previous card
  const handlePreviousCardClicked = (e) => {
    e.preventDefault();
    const indexFirst = cardInfoSection5.findIndex(
      (obj) => obj.text === areaDisplayed[0].text
    );
    if (indexFirst) {
      setareaDisplayed([]);
      setareaDisplayed((prev) =>
        prev.concat(cardInfoSection5.slice(indexFirst - 1, indexFirst + 2))
      );
    }
  };

  // Function handle previous card
  const handleNextCardClicked = (e) => {
    e.preventDefault();
    const indexLast = cardInfoSection5.findIndex(
      (obj) => obj.text === areaDisplayed[areaDisplayed.length - 1].text
    );
    if (indexLast !== cardInfoSection5.length - 1) {
      setareaDisplayed([]);
      setareaDisplayed((prev) =>
        prev.concat(cardInfoSection5.slice(indexLast - 1, indexLast + 2))
      );
    }
  };

  return (
    <Grid
      sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
      container
      spacing={2}
    >
      <Grid item>
        <Title text1={"NOS"} text2={"ARTICLES"} />
      </Grid>
      <Grid
        sx={{ p: { xs: 1, md: 5 } }}
        item
        container
        spacing={2}
        justifyContent="center"
      >
        {areaDisplayed?.map((cardInfo, index) => {
          return (
            <Grid item key={index}>
              <Link
                sx={{ elevator: 1 }}
                href={cardInfo.articleURL}
                target="_blank"
                underline="none"
              >
                <CardComponent
                  picture={cardInfo.picture ? cardInfo.picture : Article_img1}
                  text={cardInfo.text}
                  pictureHeight={100}
                  styleSection5={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  pictureWidth={"100%"}
                  color="#ffff"
                  width={350}
                  height={250}
                  noButton="true"
                  textPreviewLength={101}
                />
              </Link>
            </Grid>
          );
        })}
      </Grid>
      <Grid item container justifyContent="right">
        <IconButton onClick={handlePreviousCardClicked}>
          <ArrowCircleLeftOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleNextCardClicked}>
          <ArrowCircleRightIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default Section5;
