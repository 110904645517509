import React from 'react';
import {Grid, Paper} from '@mui/material';
import {Helmet} from 'react-helmet';
import {useTheme} from '@mui/material/styles';

import Contact_background from '../images/Contact_background.png';
import Contact_Us from '../components/Contact_Us';
import Footer from '../components/Footer/Footer';
import NavBar from '../components/navBar/NavBar';
import TopHeader from '../components/TopHeader';

function ContactPage () {
  const theme = useTheme ();
  return (
    <Paper m={3}>
      <Helmet>
        <title>Contactez avocat sur nîmes</title>
        <meta
          name="description"
          content="Avocat disponible à servir sur nimes montpelier paris"
        />
        <meta
          name="keywords"
          content={`avocat, barreau, france, docteur, droit, contacter, nimes, montepelier, service, droit général`}
        />
      </Helmet>
      <Grid container direction="column">
        <Grid
          sx={{
            background: theme.palette.secondary.light,
            backgroundImage: {md: `url(${Contact_background})`},
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.9,
          }}
        >
          <Grid item>
            <TopHeader />
          </Grid>
          <Grid item>
            <NavBar />
          </Grid>
        </Grid>
        <Grid item>
          <Contact_Us />
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ContactPage;
