import React, { useState } from "react";

import {
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Modal,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";

import { useTheme } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 25,
  p: 4,
  borderRadius: 5,
};

function CardComponent({
  style,
  styleSection5,
  title,
  subTitle,
  picture,
  autor,
  date,
  text,
  textPreviewLength,
  lists,
  width,
  height,
  pictureHeight,
  pictureWidth,
  color,
  noHover,
  noButton,
  href,
}) {
  const theme = useTheme();
  const [openList, setopenList] = useState(false);
  const [openMoreText, setopenMoreText] = useState(false);

  function getWidth() {
    if (width) {
      return width;
    } else {
      if (picture) return 600;
      else if (text) return 400;
      else return 200;
    }
  }
  /* function for onclick event of voir plus*/
  function handleOnClickVoirPlus(e) {
    e.preventDefault();
    if (lists?.length) {
      setopenList(true);
    } else if (text) {
      setopenMoreText(true);
    }
  }
  /* function handling the close lists modal*/
  function handleCloseListModal() {
    setopenList(false);
    setopenMoreText(false);
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={`${text?.slice(0, 100)}`} />
      </Helmet>
      <Card
        sx={{
          ...style,
          maxHeight: { md: 400 },
          maxWidth: { md: getWidth() },
          width: width,
          height: height,
          marginRight: 0,
          boxShadow: 5,
          display: "flex",
          bgcolor:
            color === "dark" ? theme.palette.primary.main : color && color,
          color: color === "dark" && "#fff",
          ":hover": {
            elevation: !noHover && 5,
            bgcolor: !noHover && theme.palette.primary.main,
            color: !noHover && "#fff",
          },
        }}
      >
        <Grid
          sx={{
            ...styleSection5,
            flexDirection: picture ? "column" : "column",
          }}
          container
        >
          {picture && (
            <Grid sx={{ xs: 4 }} item>
              <CardMedia
                image={picture}
                alt="Image Article"
                sx={{
                  width: pictureWidth,
                  padding: 1,
                  height: pictureHeight,
                }}
                component="img"
              />
            </Grid>
          )}
          <Grid sx={{ flexDirection: "column", xs: 8 }} item>
            <CardContent>
              <Typography
                gutterBottom
                fontSize={30}
                fontWeight={800}
                letterSpacing={0.5}
              >
                {title}
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                {subTitle}
              </Typography>
              {autor && date ? (
                <Box sx={{ display: "flex" }}>
                  <Typography
                    gutterBottom
                    sx={{
                      ":hover": {
                        color: "#fff",
                      },
                    }}
                    variant="body1"
                    fontWeight="bold"
                    // color="primary"
                  >
                    {autor} &nbsp;
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    color="text.secondary"
                  >
                    <strong>{" | "}</strong>
                    {date}
                  </Typography>
                </Box>
              ) : null}
              {text ? (
                <Typography fontSize="18">
                  {textPreviewLength && textPreviewLength < text.length
                    ? `${text.slice(0, textPreviewLength)}...`
                    : text}
                </Typography>
              ) : null}
              {textPreviewLength &&
                text.length > textPreviewLength &&
                !noButton && (
                  <Button
                    sx={{ marginBottom: 3, mt: 1 }}
                    color="inherit"
                    size="small"
                    onClick={handleOnClickVoirPlus}
                    variant="outlined"
                  >
                    Lire Plus
                  </Button>
                )}
              {lists ? (
                <List>
                  {lists.map((list, index) => {
                    return list.service ? (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <CircleIcon size={5} color="red" />
                        </ListItemIcon>
                        <ListItemText fontWeight={600} fontSize={18}>
                          {list.service}
                          <br />
                          <span
                            style={{ fontSize: "14px", fontWeight: "300px" }}
                          >
                            {list.renumeration}
                          </span>
                        </ListItemText>
                      </ListItem>
                    ) : (
                      <Typography>{list.renumeration}</Typography>
                    );
                  })}
                </List>
              ) : null}
            </CardContent>
            <CardActions>
              <Grid container justifyContent="right">
                {picture && text && !noButton && (
                  <Button
                    sx={{ marginBottom: 2 }}
                    color="inherit"
                    size="small"
                    onClick={handleOnClickVoirPlus}
                    variant="outlined"
                  >
                    Voir Plus
                  </Button>
                )}
              </Grid>
            </CardActions>
          </Grid>
        </Grid>
      </Card>
      {openList && lists && (
        <Modal
          open={openList}
          onClose={handleCloseListModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            sx={modalStyle}
            textAlign="center"
            justifyContent={"center"}
            direction="column"
          >
            <Grid item>
              <Typography gutterBottom variant="h5" fontWeight="bold">
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <List>
                {lists.map((list, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CircleIcon />
                      </ListItemIcon>
                      <ListItemText>{list}</ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </Modal>
      )}
      {openMoreText && text.length > textPreviewLength && (
        <Modal
          open={openMoreText}
          onClose={handleCloseListModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            sx={modalStyle}
            textAlign="center"
            justifyContent={"center"}
            direction="column"
          >
            <Grid item>
              <Typography
                gutterBottom
                fontSize={30}
                fontWeight={800}
                letterSpacing={0.5}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize="18">{text}</Typography>
              <Link href={href} target="_blank" underline="hover">
                Voir source{" "}
              </Link>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
}
export default CardComponent;
