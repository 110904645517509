import axios from "axios";

const getExpertise = async () => {
  try {
    let domaineCmp = "";
    await axios.get("/api/cases").then((res) => {
      if (res.status === 200) {
        domaineCmp = res?.data?.cases;
      } else {
        domaineCmp = undefined;
      }
    });

    return domaineCmp;
  } catch (error) {
    console.log("Error getting domaine competance info", error);
  }
};
export default getExpertise;