export const logo =
  'https://bnz05pap002files.storage.live.com/y4mqA_ck3zMKzNyeLD1ewZ7_4L5KaY7weJhb6ITBggnLL3JIorYS1y-GH3XMhBJY3qDFw8tQTs2e_yoLb4_ABjwIMq0ipNT7ZiJygb6I2GEM2IwS5YxWLoHC1WgWTjXqKxqrwI7UevytOmv0x871jqPRsaAMr_O0qPcSxBwscYbausIReLyINcPKPMf-LdxFwRm?width=7951&height=2152&cropmode=none';
export const lsuLink = 'https://www.letservu.com/contactus';
// export const email = "Contact@avocat-kamdem.com";
export const email = 'jf.kamdem@wanadoo.fr';
export const linkedIn = {
  label: 'Maître Jean Faustin Kamdem',
  link: 'https://www.linkedin.com/in/jean-faustin-kamdem-2075b223b/',
};
export const Pages = [
  {name: 'Accueil', url: '/'},
  {name: 'Domaine de compétences', url: '/domaine-de-competence'},
  {name: 'A Propos', url: '/a-propos'},
  {name: 'Honoraires', url: '/honoraire'},
  {name: 'Contactez-Nous', url: '/contactez-nous'},
];
export const location = [
  {name: 'Nîmes', url: 'https://goo.gl/maps/q6pYYZXcWwevAGCR9'},
  {name: 'Montpellier', url: 'https://goo.gl/maps/t7HatGkqq517jNep9'},
  {name: 'Chateauneuf du Pape', url: 'https://goo.gl/maps/31KgcvUZVzUZMtvt6'},
  {name: 'Yaoundé', url: 'https://goo.gl/maps/gU572Zq91DjJsANZ7'},
];
export const connection = [
  {
    name: 'Droit Pénal ',
    url: 'https://www.justifit.fr/b/guides/droit-penal/',
  },
  {
    name: 'Droit de la Famille',
    url: 'https://www.justifit.fr/b/guides/droit-famille/',
  },
  {
    name: 'Droit des Affaires',
    url: 'https://www.justifit.fr/b/guides/droit-des-affaires/',
  },
  {
    name: 'Droit des Étrangers',
    url: 'https://fr.m.wikipedia.org/wiki/Droit_des_%C3%A9trangers/',
  },
];
