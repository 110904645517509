import React, {useState} from 'react';
import {
  CardActionArea,
  CardMedia,
  Grid,
  IconButton,
  Paper,
  Card,
} from '@mui/material';

import {
  NavigateBeforeOutlined,
  NavigateNextOutlined,
} from '@mui/icons-material';
import {AspectRatio} from 'react-aspect-ratio';

function PictureDisplay({pictures, picture, size, picStyle, removeCard}) {
  const [currentPicture, setcurrentPicture] = useState (
    picture ? [] : pictures[0]
  );

  //   Get index of current pic
  const indexOfCurentPic = currentPic => {
    return pictures.findIndex (pic => pic === currentPic);
  };

  //   Function to handle prev
  const prevPic = () => {
    const current = indexOfCurentPic (currentPicture);
    setcurrentPicture (
      current - 1 > 0 ? pictures[current - 1] : pictures[pictures.length - 1]
    );
  };

  //   Funtion to handle next
  const nextPic = () => {
    const current = indexOfCurentPic (currentPicture);
    setcurrentPicture (
      current + 1 >= pictures.length ? pictures[0] : pictures[current + 1]
    );
  };

  //   Timeout to make picture move
  if (!picture && pictures.length > 1) {
    setTimeout (() => {
      nextPic ();
    }, 5000);
  }

  return (
    <Grid container sx={{picStyle}}>
      {!picture &&
        pictures.length > 1 &&
        <Grid
          item
          container
          xs={2}
          direction="row"
          alignItems="center"
          textAlign="center"
        >
          <Grid item xs={12}>
            <IconButton
              color="primary"
              aria-label="back"
              component="span"
              onClick={prevPic}
            >
              <NavigateBeforeOutlined />
            </IconButton>
          </Grid>
        </Grid>}
      <Grid item xs={8}>
        <Paper elevation={12} m={0}>
          <Grid
            sx={{backgroundSize: 'contain'}}
            container
            p={1}
            width="100%"
            maxHeight="200"
            maxWidth="200"
          >
            <Grid item>
              <Card sx={{position: 'relative', height: '100%'}}>
                <CardActionArea>
                  <CardMedia
                    image={picture ? picture : currentPicture}
                    alt="Image Article"
                    component="img"
                    src="img"
                    sx={{zIndex: 1, height: 'inherit'}}
                  />
                </CardActionArea>
              </Card>
              {/* <AspectRatio ratio="3/4" style={{ maxWidth: "400px" }}>
                <CardMedia
                  image={picture ? picture : currentPicture}
                  alt="Image Article"
                  sx={{
                    width: size
                      ? size.width > 200
                        ? { xs: "100%" }
                        : { md: size.width }
                      : 250,
                    // height: size ? size.height : "100%",
                    padding: 1,
                    maxHeight: { xs: 200, md: 350 },
                    // Without height undefined it won't work
                    // height: undefined,
                    // figure out your image aspect ratio
                    // aspectRatio: 135 / 76,
                  }}
                  component="img"
                  src="img"
                />
                </AspectRatio> */}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {!picture &&
        pictures.length > 1 &&
        <Grid item container xs={2} alignItems="center" textAlign="center">
          <Grid item xs={12}>
            <IconButton
              color="primary"
              aria-label="back"
              component="span"
              onClick={nextPic}
            >
              <NavigateNextOutlined />
            </IconButton>
          </Grid>
        </Grid>}
    </Grid>
  );
}

export default PictureDisplay;
