import React from "react";
import { Grid, Typography } from "@mui/material";

function Title({ text1, text2, style }) {
  return (
    <Grid sx={{ pt: 5, ...style }} item container direction="column">
      <Grid item>
        <Typography
          variant="h1"
          textTransform="uppercase"
          fontSize={14}
          fontWeight={500}
        >
          {text1}
        </Typography>
      </Grid>
      <Grid item>
        <Typography textTransform="uppercase" fontSize={30} fontWeight="bold">
          {text2}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Title;
