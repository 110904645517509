import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ContactUsButton({ text, variant = "contained" }) {
  const navigate = useNavigate();

  const redirectToContactPage = () => {
    navigate("/contactez-nous");
  };

  return (
    <Button variant={variant} onClick={redirectToContactPage}>
      {text}
    </Button>
  );
}
