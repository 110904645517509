import React, {useContext} from 'react';
import {Grid, Paper, Typography} from '@mui/material';
import {Helmet} from 'react-helmet';
import {useTheme} from '@mui/material/styles';

import AppContext from '../AppContext';
import Contact_background from '../images/Contact_background.png';
import ContactUsButton from '../components/Buttons/ContactUsButton';
import Footer from '../components/Footer/Footer';
import NavBar from '../components/navBar/NavBar';
import Title from '../components/Title';
import TopHeader from '../components/TopHeader';
import image from '../images/Section_4_image.png';

export default function Honoraires () {
  const theme = useTheme ();
  const {honorairesInfo} = useContext (AppContext);
  return (
    <Paper m={3}>
      <Helmet>
        <title>Avocat bon prix nimes</title>
        <meta
          name="description"
          content="Avocat moins chere nimes montpelier paris"
        />
        <meta
          name="keywords"
          content={`avocat, moins, chere, paris, nimes, montepelier, service`}
        />

        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />

      </Helmet>
      <Grid container direction="column">
        <Grid
          sx={{
            background: theme.palette.secondary.light,
            backgroundImage: {md: `url(${Contact_background})`},
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.9,
          }}
        >
          <Grid item>
            <TopHeader />
          </Grid>
          <Grid item>
            <NavBar />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            // backgroundColor: theme.palette.secondary.light,
            backgroundImage: {md: `url(${image})`},
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'calc(100% - 10px) center',
            margin: 6,
          }}
          elevation={0}
          m={0}
          marginLeft={5}
          flexDirection={'column'}
        >
          <Grid item sx={{marginBottom: 2, width: '70%'}}>
            <Title
              text2={
                'Des honoraires adaptés selon votre situation et votre activité'
              }
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={1}
            sx={{width: '90%'}}
            textAlign={'left'}
          >
            <Grid sx={{margin: 2, marginTop: 2}}>
              <Typography fontSize={24} fontWeight={'bold'} p={2}>
                1- Pour les particuliers{' '}
              </Typography>
              <p>
                Sur Devis (Suite à un appel téléphonique gratuit et un premier
                rendez-vous facturé 120 euros TTC).<br />
                <br />

                <Grid marginLeft={3}>
                  <ul>
                    <li>Possibilité de régler en 3x ou 4x.</li><br />
                    <li>Votre Avocat dédié.</li><br />
                    <li>Les assurances juridiques sont acceptées.</li><br />
                  </ul>
                </Grid>
              </p>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={1}
            sx={{width: '90%'}}
            textAlign={'left'}
          >
            <Grid sx={{margin: 2, marginTop: 2}}>
              <Typography fontSize={24} fontWeight={'bold'} p={2}>
                2- Pour les entreprises{' '}
              </Typography>
              <p>
                Nous proposons toujours de vous rencontrer dans vos locaux, afin
                de bien comprendre votre activité et vos besoins.<br />
                <br />
                <Grid marginLeft={3}>
                  <ul>
                    <li>Votre avocat dédié.</li><br />
                    <li>Un suivi méticuleux des travaux.</li><br />
                    <li>Disponibilité et réactivité.</li><br />
                    <li>Invitations aux formations du Cabinet.</li><br />
                    <li>Réunions de suivies incluses et gratuites.</li><br />
                  </ul>
                </Grid>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={4} justifyContent="center" mb={2}>
          <ContactUsButton
            text={'Programmer Votre premiére consultation gratuite Maintenant'}
            variant="contained"
          />
        </Grid>
        <Grid>
          <Footer />
        </Grid>
      </Grid>
    </Paper>
  );
}
